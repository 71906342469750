import React from 'react';

import {
    PressPostCardDate,
    PressPostCardImg,
    PressPostCardWrapper
} from "../styled/cards/PressCardComponents";
import {Body} from "../styled/typography/Typography";

const PressPostCard = ({avatar, title, short_description, slug, date}) => {
    return (
        <PressPostCardWrapper to={slug}>
            <PressPostCardImg avatar={avatar}/>
            <PressPostCardDate>
                {date}
            </PressPostCardDate>
            <Body color="primary-brand" bold marginTop="small" padding="medium">
                {title}
            </Body>
            <Body color="body-text" small marginTop="small" padding="medium" marginBottom="small">
                {short_description}
            </Body>
        </PressPostCardWrapper>
    );
};

export default PressPostCard;