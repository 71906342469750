import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";
import PressPostCard from "../../components/cards/PressPostCard";

import Col from "../../components/styled/grid/Col";
import {Span, Title} from "../../components/styled/typography/Typography";

const Blogs = ({data}) => {

    const {nodes} = data.blogposts;
    const {blogpage} = data;

    return (
        <Layout lang={blogpage.lang} seo={blogpage.SEO}>
            <SectionLayoutGrid id={"blog"} customBackground="middle">
                <Col>
                    <Title color="white">
                        Balasys<br/>
                        <Span color="primary-brand">{blogpage.lang === "hu" ? "Blog Bejegyzések" : "Blog Posts"}</Span>
                    </Title>
                </Col>
                {nodes.map((item, index) => ((blogpage.lang === "hu" && item.isHungarian) ?
                        <Col span={4} grid key={index}>
                            <PressPostCard
                                key={item.id}
                                avatar={item.avatar.url}
                                title={item.title}
                                date={item.date}
                                short_description={item.short_description}
                                slug={"/blogs/" + item.slug}
                                locale={blogpage.lang}
                            />
                        </Col>
                        : null
                ))}
                {nodes.map((item, index) => ((blogpage.lang === "en" && item.isEnglish) ?
                        <Col span={4} grid key={index}>
                            <PressPostCard
                                key={item.id}
                                avatar={item.avatar.url}
                                title={item.title}
                                date={item.date}
                                short_description={item.short_description}
                                slug={"/blogs/" + item.slug}
                                locale={blogpage.lang}
                            />
                        </Col>
                        : null
                ))}
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSingleBlogPages($locale: String) {
         blogpage: strapiBlogPages(lang: { eq: $locale }) {
          lang
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
      blogposts: allStrapiBlogposts(sort: {fields: date, order: DESC}) {
        nodes {
          author
          avatar {
            url
          }
          content
          date(formatString: "LL", locale: $locale)
          id
          short_description
          isEnglish
          isHungarian
          slug
          title
        }
      }
   }
 `

export default Blogs
